<template>
  <v-container class="px-6">
    <v-list-item three-line>
      <v-list-item-content>
        <p class="display-1 text--primary">
          {{ $t("menu.faq_text") }}
        </p>
      </v-list-item-content>
    </v-list-item>
    <v-row justify="center" class='pt-6 pl-6 pr-12 pb-12'>
      <v-expansion-panels popout>
        <v-expansion-panel
          v-for="(item,i) in faq"
          :key="i"
        >
          <v-expansion-panel-header>
            <span class='bold'>
              <strong>{{item.question}}</strong>
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            {{item.answer}}
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "IndexView",
  components: {
  },

  data: () => ({
    faq: [
      {
        "question": "O que é o blockchain?",
        "answer": "O conceito de Blockchain está associado a um livro-razão, que registra as transações em uma rede. O blockchain armazena dados em blocos interligados em uma cadeia. Os dados são cronologicamente consistentes porque não é possível excluir nem modificar a cadeia sem o consenso da rede. Como resultado, você pode usar a tecnologia blockchain para criar um registro  inalterável ou imutável para monitorar pedidos, pagamentos e outras transações. O sistema tem mecanismos integrados que impedem entradas de transações não autorizadas e criam consistência na visualização compartilhada dessas transações."
      },
      {
        "question": "O que é um token?",
        "answer": "Token é a representação digital de um ativo – dinheiro, propriedade e investimento, por exemplo – em uma blockchain. No caso deste projeto o token representa um produto agrícola (grãos). Podemos considerar que 1Tn de grão = 1 token de “criptogrão”"
      },
      {
        "question": "Qual a utilidade do Token?",
        "answer": "Neste projeto está sendo disponibilizado um token, que serve como garantia de posse de um ativo rastreado seguindo os mais rigorosos padrões ESG. Ao compensar os tokens, o comprador recebe um certificado contendo todos os dados de rastreabilidade - desde a fazenda até a entrega do produto. Todas as operações rastreadas são gravadas no blockchain, garantindo imutabilidade e transparência a esta transação. O certificado pode ser utilizado para comprovar no mercado a utilização de produtos sustentáveis.  "
      },
      {
        "question": "Como posso gerar meu certificado de rastreabilidade?",
        "answer": "O certificado de rastreabilidade é gerado no momento da compensação dos tokens através da sua carteira digital. Este certificado fica disponível para download na própria plataforma."
      },
      {
        "question": "Posso transferir e compensar tokens com valores quebrados?",
        "answer": "Não, somente com valores inteiros. Valor mínimo para cada transação é de 1 token (= 1 tonelada de grãos)."
      },
      {
        "question": "A transferência do token está atrelada a entrega física dos grãos?",
        "answer": "Sim. A transferência dos tokens somente acontecerá na medida que o produto físico for entregue."
      },
      {
        "question": "Preciso ter uma conta no MetaMask para gerar meu certificado?",
        "answer": "Sim, todas as operações na plataforma dependem de conexão com a Metamask. A Metamask é uma crypto wallet (carteira de criptoativos), um software que permite criar e armazenar chaves públicas e privadas, interagir com o blockchain e autorizar as operações de recebimento e envio de criptoativos. A cripto wallet funciona de maneira similar ao aplicativo de online banking. Embora o dinheiro fique armazenado no Banco, é através do App que são autorizadas as transações. Com o Blockchain funciona de mesma maneira. Os ativos estão no blockchain mas é necessário a cripto wallet para interagir com o blockchain e autorizar as transações."
      },
      {
        "question": "Quantas vezes posso compensar meu token?",
        "answer": "Cada token pode ser compensado apenas uma vez."
      },
      {
        "question": "De onde vem as informações do meu certificado?",
        "answer": "As informações contidas no certificado vêm do processo de rastreabilidade realizado desde a originação dos grãos."
      },
      {
        "question": "O que acontece depois que compensar meu token?",
        "answer": "A operação de compensação serve para retirar do mercado os tokens que já foram utilizados e evitar dupla contagem. Enquanto os tokens estão na rede sendo transacionados entre usuários eles funcionam como uma espécie de crédito imobilizado.Quando um comprador reivindica a compensação dos tokens é quando é feita a sua aposentadoria, ou seja, o token é retirado de circulação. Ao fazer isso o comprador recebe seu certificado de compensação, que leva junto toda a rastreabilidade daquele ativo."
      },
      {
        "question": "Como terei acesso ao token?",
        "answer": "O acesso aos tokens é feito pela plataforma da AgTrace, onde são realizadas todas as transações."
      },
      {
        "question": "Como tenho acesso às informações de rastreabilidade?",
        "answer": "A informação de rastreabilidade está contida no certificado de compensação."
      },
      {
        "question": "É necessário logar no MetaMask para ter acesso a minha carteira?",
        "answer": "Sim, após entrar na plataforma você precisa logar na sua Metamask. Um botão laranja no canto superior direito indica onde você conecta a AgTrace à Metamask."
      },
      {
        "question": "Que tipo de ativo estamos realizando na transação? é fungível (o leitor sabe o que é fungível) ou não?",
        "answer": "Estamos utilizando o padrão de token ERC1155. Este é um tipo de token que tem a capacidade de armazenar, sob seu controle, tokens que podem agir como se fossem um token fungível ou não-fungível, ou ambos ao mesmo tempo no mesmo endereço.Na prática isso significa que os grãos (tokens fungíveis) ficam atrelados a um número de transferência (tokens não fungíveis). Isso é o que possibilita a rastreabilidade dos tokens."
      },
      {
        "question": "Qual o blockchain utilizado? Posso migrar para outras redes?",
        "answer": "A rede de blockckahin utilizada é a Polygon e todas as transações ficam registradas nela."
      },
      {
        "question": "É seguro? Que tipo de segurança a plataforma ou solução me oferece nesta transação?",
        "answer": "Sim!.A plataforma AgTrace traz toda a tecnologia dos criptoativos e suas redes associadas permitem dar escalabilidade para o processo de originação e comercialização de produtos agrícolas. Tem outras dúvidas não respondidas? Procure o time do projeto!"
      }
    ]

  }),

  computed: {
    ...mapGetters({

    }),
  },

  methods: {
    ...mapActions({
    }),
    async initialize() {

    },
  },
};
</script>

<style scoped>
.zebra-bg tr:nth-child(even) {
  background-color: #f2f2f2 !important;
}

a {
  text-decoration: none !important;
}
</style>
