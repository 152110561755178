import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"px-6"},[_c(VListItem,{attrs:{"three-line":""}},[_c(VListItemContent,[_c('p',{staticClass:"display-1 text--primary"},[_vm._v(" "+_vm._s(_vm.$t("menu.faq_text"))+" ")])])],1),_c(VRow,{staticClass:"pt-6 pl-6 pr-12 pb-12",attrs:{"justify":"center"}},[_c(VExpansionPanels,{attrs:{"popout":""}},_vm._l((_vm.faq),function(item,i){return _c(VExpansionPanel,{key:i},[_c(VExpansionPanelHeader,[_c('span',{staticClass:"bold"},[_c('strong',[_vm._v(_vm._s(item.question))])])]),_c(VExpansionPanelContent,[_vm._v(" "+_vm._s(item.answer)+" ")])],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }